<template>
  <div class="page">
    <head-nav />
    <div
      class="inner-ban"
      :style="{ backgroundImage: 'url(' + banner + ')' }"
    ></div>
    <div class="main">
      <div class="auto wrap flexLr">
        <lefter :bg="menuBackground" @click="optionalFun" />
        <!-- <div class="main-l">
          <div
            class="menu-titbox"
            :style="{ backgroundImage: 'url(' + menuBackground + ')' }"
          >
            核心技术
          </div>
          <ul class="menu-list">
            <li
              :class="optionalIndex == item.typeCodeNum ? 'curBg' : ''"
              v-for="(item, index) in optionalLists"
              :key="index"
              @click="optionalFun(item)"
            >
              {{ item.names }}
            </li>
          </ul>
        </div> -->
        <div class="main-r flex-1 bg-white">
          <div class="conbx" v-show="!dialogTableVisible">
            <div class="inner-tt">
              <span class="bt">{{ optionalTitle }}</span>
            </div>
            <div class="main-wrap cx-wrap">
              <!-- <ul class="cx-list flexUl">
                <li
                  v-for="(item, index) in optionalDetailsList"
                  :key="index"
                  class="item"
                  @click="optionalDetailsFun(item)"
                >
                  <div class="datebox">
                    {{ item.Sort }}
                  </div>
                  <div class="info">
                    <div :to="item.path">
                      <h6 class="title clamp1">{{ item.ItemName }}</h6>
                    </div>
                    <div class="txt clamp">{{ item.SF1 }}</div>
                  </div>
                </li>
              </ul> -->
              <ul class="kjcg-list">
                <li
                  v-for="(item, index) in optionalDetailsList"
                  :key="index"
                  class="item flex flex-pack-justify"
                  @click="optionalDetailsFun(item)"
                >
                  <h6 class="title ellipsis" :title="item.ItemName">
                    {{ item.ItemName }}
                  </h6>

                  <span class="date">{{
                    item.SF49
                      ? common.dateFormat("YYYY-mm-dd", item.SF49.toString())
                      : common.dateFormat("YYYY-mm-dd", item.CrDate.toString())
                  }}</span>
                </li>
              </ul>
              <div class="pagelist" v-show="dataCount > 10">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 15, 20, 25]"
                  :page-size="pageSize"
                  :total="dataCount"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div class="popbx" v-if="dialogTableVisible">
            <el-dialog
              :title="detailsTitle"
              :visible.sync="dialogTableVisible"
              :modal="false"
              class="new-dialog"
            >
              <detail ref="sonV" />
            </el-dialog>
          </div>
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
import detail from "@/components/detail.vue";

import $ from "jquery";
import Lefter from "@/components/lefter.vue";
export default {
  name: "",
  data() {
    return {
      banner: require("@/assets/images/cx_01.jpg"),
      menuBackground: require("@/assets/images/navBg01.jpg"),
      optionalIndex: "CX027",
      optionalLists: [
        {
          names: "高坝工程",
          rootIdNum: "10000",
          itemIdNum: "115",
          typeCodeNum: "CX027",
        },
        {
          names: "水网工程",
          rootIdNum: "10000",
          itemIdNum: "117",
          typeCodeNum: "CX029",
        },
        {
          names: "数字工程",
          rootIdNum: "10000",
          itemIdNum: "119",
          typeCodeNum: "CX031",
        },
        {
          names: "新型电力系统与储能工程",
          rootIdNum: "10000",
          itemIdNum: "118",
          typeCodeNum: "CX030",
        },
        {
          names: "其他",
          rootIdNum: "10000",
          itemIdNum: "120",
          typeCodeNum: "CX032",
        },
      ],
      rootIdNum: "",
      itemIdNum: "",
      typeCodeNum: "",
      optionalTitle: "",
      optionalDetailsList: [],
      dialogTableVisible: false,
      detailsCentent: "",
      detailsTitle: "",

      //默认第一页
      currentPage: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 9,
    };
  },
  components: {
    headNav,
    foot,
    detail,
    Lefter,
  },
  mounted() {
    let that = this;
    var tmpModel = that.optionalLists[0];
    if (this.$route.query.typeCode) {
      var typeCode = this.$route.query.typeCode;
      for (var index in this.optionalLists) {
        var item = this.optionalLists[index];
        if (item.typeCodeNum == typeCode) {
          this.optionalFun(item);
          tmpModel = item;
        }
      }
    }
    // 初始化加载奖励列表
    that.rootIdNum = tmpModel.rootIdNum;
    that.itemIdNum = tmpModel.itemIdNum;
    that.typeCodeNum = tmpModel.typeCodeNum;
    that.send(
      that.rootIdNum,
      that.itemIdNum,
      that.typeCodeNum,
      that.currentPage
    );
    that.optionalTitle = tmpModel.names;

    that.UrlDetailRedirect();
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.typeCode) {
        var typeCode = this.$route.query.typeCode;
        for (var index in this.optionalLists) {
          var item = this.optionalLists[index];
          if (item.typeCodeNum == typeCode) {
            this.optionalFun(item);
          }
          if (item.typeCodeNum == typeCode && this.$route.query.supItemId) {
            this.UrlDetailRedirect();
          }
        }
      } else {
        var item = this.optionalLists[0];
        this.optionalFun(item);
      }
    },
  },

  methods: {
    optionalFun(item) {
      let that = this;
      that.optionalIndex = item.typeCodeNum;
      that.optionalTitle = item.names;
      that.dialogTableVisible = false;
      that.rootIdNum = item.rootIdNum;
      that.itemIdNum = item.itemIdNum;
      that.typeCodeNum = item.typeCodeNum;
      // console.log(that.rootIdNum,that.itemIdNum,that.typeCodeNum)
      that.send(that.rootIdNum, that.itemIdNum, that.typeCodeNum);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 加载奖励列表
    send(rootIdNum, itemIdNum, typeCodeNum, currentPage) {
      let that = this;
      if (rootIdNum == "" && itemIdNum == "" && typeCodeNum == "") {
        rootIdNum = that.rootIdNum;
        itemIdNum = that.itemIdNum;
        typeCodeNum = that.typeCodeNum;
      }
      var obj = {
        rootId: rootIdNum,
        itemId: itemIdNum,
        typeCode: typeCodeNum,
        pageIndex: currentPage,
        pageSize: that.pageSize,
        itemOrdey: true,
        DisableItemContent: true,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function (res) {
          var datas = res.Data;
          that.optionalDetailsList = datas.Items;
          that.dataCount = res.Count;
          //console.log(datas.Items);
        },
      });
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let that = this;
      //console.log(`当前页: ${val}`);
      that.send("", "", "", val);
    },
    // 奖励详情
    optionalDetailsFun(item) {
      let that = this;
      let detailsarr = "";
      var obj = {
        rootId: item.RootId,
        itemId: item.ItemId,
        typeCode: item.TypeCode,
        supItemIdNum: item.ParentId,
        userId: this.userToken,
      };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",

        url: this.apiUrl + "/Api/ItemApi/EditDetail",
        data: centent,
        success: function (res) {
          detailsarr = res.Data.Attr;
          //console.log(res)
          that.$refs.sonV.childMethods(res);
          for (var i = 0; i < detailsarr.length; i++) {
            if (detailsarr[i].Source == "ItemName") {
              that.detailsTitle = detailsarr[i].Value;
            }
          }
        },
      });
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.dialogTableVisible = true;
    },

    //地址详情页跳转
    UrlDetailRedirect() {
      let that = this;
      let rootIdNum = 10000;
      let itemIdNum = this.$route.query.itemId;
      let typeCodeNum = this.$route.query.typeCode;
      let supItemIdNum = this.$route.query.supItemId;
      let names = this.$route.query.name;
      if (!(itemIdNum > 0)) return;
      var obj = {
        RootId: rootIdNum,
        ItemId: itemIdNum,
        TypeCode: typeCodeNum,
        ParentId: supItemIdNum,
      };
      that.optionalIndex = typeCodeNum;
      if (names != "" && names != undefined) {
        setTimeout(function () {
          that.optionalTitle = names;
          that.send(rootIdNum, itemIdNum, typeCodeNum);
        }, 0);
        return;
      }
      that.optionalDetailsFun(obj);
    },
  },
};
</script>

<style>
.justify_content {
  display: flex;
  justify-content: center;
}

.cx-list li {
  cursor: pointer;
}
</style>
